import Vue from 'vue'
import router from './router'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Vuex from 'vuex'
import store from './store'
import Toasted from 'vue-toasted'
import Vuelidate from 'vuelidate'
import Vidle from 'v-idle';
import InstantSearch from "vue-instantsearch";
import VueCurrencyInput from 'vue-currency-input'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fab, fas, far, fal);

import { dom } from '@fortawesome/fontawesome-svg-core'
import axios from 'axios'
import VueAxios from 'vue-axios';
import VueTheMask from 'vue-the-mask';
import VueGtag from 'vue-gtag'

import '@/directives/handleOutsideClick.js';
import 'pdfjs-dist/webpack' //Initializes some parts of pdfjs

dom.watch() // This will kick of the initial replacement of i to svg tags and configure a MutationObserver

Vue.component('font-awesome-icon', FontAwesomeIcon);

export const serverBus = new Vue();

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Vuex)
Vue.use(Vuelidate)
Vue.use(Toasted);
Vue.use(VueAxios, axios);
Vue.use(VueTheMask);
Vue.use(Vidle);
Vue.use(InstantSearch);
Vue.use(VueCurrencyInput)

Vue.prototype.$eventBus = new Vue();

import trackingService from '@/services/api/tracking';
import { PluginObject } from 'vue/types/umd'
trackingService.trackReferer(document.referrer);
//Register default toast setups
Vue.toasted.register('qh_toast_success', 
  (payload) => {    
    return '<span>' + payload.message + '</span>';    
  },
  {
    iconPack: 'custom-class',    
    icon: {
      name: 'fa1 fa-check-circle',
      after: false
    },
    position: 'top-center',
    type: 'success',
    theme: 'toasted-primary',
    duration: 10000,
    className: 'qh-toast-style',
    closeOnSwipe: true,
    action: {
        text: '',
        onClick: (e, toastObject) => {
            toastObject.goAway(0);
        }
    }
  }
);

Vue.toasted.register('qh_toast_success_dark', 
  (payload) => {    
    return '<span>' + payload.message + '</span>';    
  },
  {
    iconPack: 'custom-class',    
    icon: {
      name: 'fal fa-check-circle',
      after: false
    },
    position: 'top-center',
    type: 'success',
    theme: 'toasted-primary',
    duration: 10000,
    className: 'qh-toast-style qh-toast-style-dark',
    closeOnSwipe: true,
    action: {
        text: '',
        onClick: (e, toastObject) => {
            toastObject.goAway(0);
        }
    }
  }
);

Vue.toasted.register('qh_toast_error', 
  (payload) => {    
    if(payload)
      return '<span>' + payload + '</span>';    
    else
      return '<span>An unexpected error occurred. Please try again later.</span>'
  },
  {
    iconPack: 'custom-class',    
    icon: {
      name: 'fal fa-exclamation-circle',
      after: false
    },
    position: 'top-center',
    type: 'error',
    theme: 'toasted-primary',
    duration: 10000,
    className: 'qh-toast-style',
    closeOnSwipe: true,
    action: {
        text: '',
        onClick: (e, toastObject) => {
            toastObject.goAway(0);
        }
    }
  }
);

Vue.config.productionTip = false;

store.dispatch('registration/initialize');
store.dispatch('unlockAccount/initialize');
store.dispatch('accountUpdate/initialize');
store.dispatch('forgotPassword/initialize');
store.dispatch("settings/initialize")
  .then(createApplication)
  .catch(createApplication);

async function createApplication() {
  await store.dispatch('currentUser/initialize');

  const gaKey = store.getters['settings/googleAnalyticsKey'];
  if (gaKey) {
    Vue.use(VueGtag, {
      config: {
        id: gaKey
      }
    }, router);
  }

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        const destination =
          !store.getters['currentUser/isEmployee']
            ? 'Login'
            : 'LoginAdmin';
    
        router.push({ name: destination });
      }

      return Promise.reject(error);
    }
  );

  new Vue({
    render: h => h(App),
    router,
    store,
    provide: { toasted: Vue.toasted }
  }).$mount('#app');
}
